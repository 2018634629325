<template>
  <div class="p4b__parent bg-white">
    <div
      v-if="showMessage"
      class=""
    >
      <div
        v-if="announcement.image"
        class="p4b__onboarding-logo"
      >
        <img
          :src="announcement.image"
          alt="Logo"
          class="d-block w-100 h-100"
        >
      </div>
      <div
        class="p-2 bg-white"
      >
        <b-badge
          v-for="(cat, catIndex) in announcement.categories"
          :key="catIndex"
          variant="light-pale"
          class="text-capitalize mr-25"
        >
          {{ cat.name }}
        </b-badge>
        <div class="p4b__onboarding-title pale-color mt-50">
          <h6>
            {{ announcement.title }}
          </h6>
        </div>
        <span class="light-black-color">{{ dateFormatWithFormatParam(announcement.createdAt, 'DD MMM YYYY hh:mma') }}</span>
        <hr class="hr-color">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="rich-text-modify pale-color-content"
          v-html="announcement.content"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BBadge } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-white.png'),
      // eslint-disable-next-line global-require
      logoWhiteLargeImg: require('@/assets/images/frontend/p4b/logo-white-large.png'),
      // eslint-disable-next-line global-require
      logoWhiteMobImg: require('@/assets/images/frontend/p4b/logo-white-mob.png'),
      announcement: {},
      showMessage: false,
    }
  },
  beforeMount() {
    document.body.classList.add('p4b__bg')
    this.$http.get(`front-end/announcement/${this.$route.params.id}/show`, { params: { token: this.$route.params.token } })
      .then(response => {
        if (response.data.success) {
          this.showMessage = true
          this.announcement = response.data.announcement || {}
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>
